<template>
  <div class="test">
    <div class="testBox">
      <div v-loading="loading" class="box" element-loading-background="rgba(0, 0, 0, 0)">
        <div v-if="total!=0">
          <el-table
            :data="tableData"
            :header-cell-style="{ backgroundColor:'#fafbfb',textAlign:'center',fontSize: '16px',fontWeight:500,color:'#000'}"
            :row-style="{marginTop:'16px',borderSpacing:'20px'}"
            :cell-style="{textAlign:'center',color:'#000'}"
            style="width: 100%;height: 750px;
                    background: #edf1f7;
                    border-collapse: separate !important;
                    border-spacing: 10px !important;
                    "
          >
            <el-table-column
              fixed
              prop="name"
              label="考试名称"
              :show-overflow-tooltip="true"
              width="300"
            />
            <el-table-column
              prop="timeStatus"
              label="考试状态"
              width="100"
            />
            <el-table-column
              prop="timeRange"
              label="考试时间"
              width="300"
            />
            <el-table-column
              prop="submitType"
              label="交卷状态"
              width="160"
            />
            <el-table-column
              prop="score"
              label="分数"
              width="110"
            />
            <el-table-column
              fixed="right"
              label="操作"
              width="200"
            >
              <template slot-scope="scope">
                <!--一考完和未考完-->
                <el-button v-if="joinJudge(scope.row)" type="text" size="small" @click="tokeTest(scope.row,0)">参加考试</el-button>
                <el-button v-if="catJudge(scope.row)" type="text" size="small" @click="tokeTest(scope.row,1)">查看试卷</el-button>
                <el-button v-if="attach(scope.row)" type="text" size="small" @click="downLoad(scope.row)">附件信息</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!--                <div class="pageBox">-->
          <!--                    <div style="text-align: right;" class="page">-->
          <!--                        <el-pagination-->
          <!--                                hide-on-single-page-->
          <!--                                @current-change="currentChange"-->
          <!--                                :current-page="currentPage"-->
          <!--                                :page-size="pageSize"-->
          <!--                                layout=" prev, pager, next"-->
          <!--                                :total="total">-->
          <!--                        </el-pagination>-->
          <!--                    </div>-->
          <!--                </div>-->
          <!--翻页-->
          <div class="changePage">
            <el-pagination
              background
              :current-page.sync="currentPage"
              :page-size="pageSize"
              layout="prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
        <el-empty v-else style="min-height: 500px" class="none-box" description="暂无数据" :image="require('@/assets/images/empty.png')" />
      </div>
    </div>
    <index-bottom />
  </div>
</template>

<script>
import indexBottom from '../../components/indexBottom'
import { getTestPaperList } from '../../api/course'
export default {
  name: 'Test',
  components: { indexBottom },
  data() {
    return {
      loading: true,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      // 是否考试结束
      hasResult: false
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.currentPn = this.pageSize * (this.currentPage - 1) + 1
      this.onLoad()
      console.log(`当前页: ${val}`)
    },
    // 获取列表
    onLoad() {
      const obj = {
        studentId: this.$store.state.user.userInfo.id,
        pageSize: this.pageSize,
        pageNum: this.currentPage
      }
      getTestPaperList(obj).then(res => {
        if (res.code === 200) {
          this.loading = false
          this.total = res.total
          console.log(res)
          const nowDate = new Date()
          // 处理数据
          res.rows.forEach(i => {
            i.timeRange = i.startTime.substring(0, 16) + '--' + i.endTime.substring(0, 16)
            i.submitType = i.isSubmit === 1 ? '已提交' : '未提交'
            i.timeStatus = new Date(i.startTime) > nowDate ? '未开始' : new Date(i.endTime) > nowDate ? '进行中' : '已结束'
            i.timeStatusNum = new Date(i.startTime) > nowDate ? 0 : new Date(i.endTime) > nowDate ? 1 : 2
            i.score = i.score == null ? '暂无' : i.score
          })
          console.log(res.rows)
          this.tableData = res.rows
        }
      })
    },
    // 参加考试按钮显示状态
    joinJudge(data) {
      const nowDate = new Date()
      const start = new Date(data.startTime)
      const end = new Date(data.endTime)
      // 如果考试时间不到或者考试时间结束，不显示参加考试
      if (start > nowDate) {
        return false
      } else if (end > nowDate) {
        return true
      } else if ((data.isSubmit === 0 || data.isSubmit === null) && end < nowDate) {
        return true
      } else {
        return false
      }
    },
    // 是否展示查看试卷的按钮
    catJudge(data) {
      const nowDate = new Date()
      const end = new Date(data.endTime)
      // 考试时间结束，并且提交过的，展示此按钮
      return end < nowDate && data.isSubmit === 1
    },
    // 是否展示附件信息的按钮
    attach(data) {
      return data.isReleased === 1
    },
    // 参加考试
    tokeTest(data, hasResult) {
      console.log(data)
      this.$router.push({
        path: '/testDetail',
        query: {
          paperId: data.paperId,
          paperLogId: data.paperLogId,
          id: data.id,
          paperName: data.name,
          timeRange: data.timeRange,
          hasResult,
          isReleased: data.isReleased,
          publishType: data.publishType
        }
      })
    },
    //   查看试卷

    // 附件下载
    downLoad(data) {
      if (data.fileUrls == '' || data.fileUrls == null || data.fileUrls == undefined) {
        this.$message.warning('暂无附件')
      } else {
        this.$download.name(data.fileUrls)
      }
    },
    // 切换分页
    currentChange(val) {
      this.currentPage = val
      console.log(val)
      this.onLoad()
    }
  }
}
</script>

<style scoped lang="less">
    /deep/ .el-pagination.is-background .el-pager li{
        background: white;
    }
    /deep/ .el-table__body{
        border-collapse: separate;
        border-spacing:0 10px;
        /*table-layout:auto !important;*/
    }
    .changePage{
      margin-top: 10px;
        text-align: right;
    }
    .test{
        .testBox{
            width: 1200px;
            min-height: 650px;
            /*background: #ffffff;*/
            margin: 0px auto;
            .box{
                width: 1200px;
                margin: 0px auto;
                //padding-top: 19px;
                padding-bottom: 19px;
                ::v-deep.el-table{
                    .el-table__empty-block{
                        width: 100% !important;
                        background: #fff;
                        min-height: 600px;
                    }
                }

            }
        }
        .pageBox{
            width: 1200px;
            margin-top: 20px;
            height: 40px;
            position: relative;
            .page{
                position: absolute;
                right: 40px;
                bottom: 10px;
                border: 1px solid #E8E8E8;
                width: auto;
            }

            ::v-deep.el-pagination{
                .el-pager{
                    .number{
                        color: #999999;
                    }
                    .active{
                        color: #39A5EF;
                    }
                    .more{
                        color: #999999;
                    }
                }
            }
        }

    }
</style>
