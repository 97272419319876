import request from "@/utils/request";

//作业列表
export function getHomeworkList(data) {
  return request({
    url: `api/tmClassTaskPublish/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//作业详情
export function getHomeworkDetail(data) {
  return request({
    url: `api/tmClassTaskPublish/getInfoList`,
    method: 'post',
    data
  })
}
//练习题详情
export function getExercisesDetail(data) {
  return request({
    url: `api/tmClassExercisesPublish/getInfo?id=${data.id}`,
    method: 'get',
  })
}
//练习题idList
export function getExercisesIdList() {
  return request({
    url: `api/tmClassExercisesPublish/idList`,
    method: 'get',
  })
}
//练习题保存
export function submitExervises(data) {
  return request({
    url: `api/tmClassExercisesPublish/submit`,
    method: 'post',
    data
  })
}
//作业详情(新)
export function getStudentInfoList(data) {
  return request({
    url: `api/tmClassTaskPublish/getStudentInfoList?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: 'post',
    data
  })
}
//保存题目
export function saveStudentAnswer(data) {
  return request({
    url: `api/tmStudentTaskQuestion/submit`,
    method: 'post',
    data
  })
}
//提交作业
export function submitStudentAnswer(data) {
  return request({
    url: `api/tmStudentTaskQuestion/endSubmit`,
    method: 'post',
    data
  })
}

//查询学生反馈和老师对作业的评语
export function getFeedback(data) {
  return request({
    url: `api/tmClassTaskPublish/getInfo?id=${data.id}`,
    method: 'get',
  })
}
//考试列表
export function getTestList(data) {
  return request({
    url: `api/tmClassPaperPublish/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//考试列表（新）
export function getTestPaperList(data) {
  return request({
    url: `api/tmClassPaperPublish/paperList?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}

//试卷详情
export function getTestDetail(data) {
  return request({
    url: `api/tmClassPaperPublish/getInfoList`,
    method: 'post',
    data
  })
}
//参加考试查询考试详情
export function joinTestGetTestDetail(data) {
  return request({
    url: `api/tmClassPaperPublish/joinTest`,
    method: 'post',
    data
  })
}
//查看考试（无成绩）查询考试详情
export function checkTestPaperDetail(data) {
  return request({
    url: `api/tmClassPaperPublish/checkPaper`,
    method: 'post',
    data
  })
}
//查看考试（有成绩）查询考试详情
export function checkTestHavePaperDetail(data) {
  return request({
    url: `api/tmClassPaperPublish/checkTest`,
    method: 'post',
    data
  })
}
//参加考试,考试题的保存
export function PaperQuestionSubmit(data) {
  return request({
    url: `api/tmStudentPaperQuestion/submit`,
    method: 'post',
    data
  })
}
//参加考试,交卷
export function submitPaper(data) {
  return request({
    url: `api/tmStudentPaperQuestion/endSubmit`,
    method: 'post',
    data
  })
}
//练习题列表
export function getExeList(data) {
  return request({
    url: `api/tmClassExercisesPublish/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//练习题状态修改
export function getExeEdit(data) {
  return request({
    url: `api/tmClassExercisesPublish/edit`,
    method: 'post',
    data
  })
}
